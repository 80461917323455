import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

// import TopImage from "../components/topImage"

import "../assets/style.scss"
// import "../assets/global.scss"
import { Link } from "gatsby"

import Pic1 from "../images/smartphone.svg"
import Pic2 from "../images/photos.svg"
import Pic3 from "../images/browser.svg"
import Pic4 from "../images/memo.svg"

// test


const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="main">
      <section className="contentsList">
      
        <div className="contentsListItem iphone">
          <Link className="text-black" to="/apps/">
          <img className="image" src={Pic1} />
          <h2>iPhone App</h2>
          </Link>
        </div>
        
        <div className="contentsListItem products">
          <Link className="text-black" to="/product/">
          <img className="image" src={Pic2} />
          <h2>Product</h2><p>Welcome Board<br/>CD<br/>T-Shirts</p>
          </Link>
        </div>
        {/* <div className="contentsListItem">
            <Link className="gatsbyLink text_white" to="/webdev/"><h2>WebDevelopping - SassaChikusan</h2></Link>
        </div> */}
        <div className="contentsListItem about">
          <Link className="text-black" to="/about/">
          <img className="image" src={Pic3} />
          <h2>About</h2>
          </Link>
        </div>
        <div className="contentsListItem blog">
          <a href="https://blog.ushiya.net" className="text-black">
          <img className="image" src={Pic4} />
          <h2>Blog</h2>
          </a>
        </div>
      </section>
    </div>
  </Layout>
)

export default IndexPage
